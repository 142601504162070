<!-- anchor-follow -->
<template>
  <div class="anchor-follow">
    <!-- 查询表单 -->
    <div class="anchor-follow-form">
      <el-form
        :model="model"
        inline
        size="small"
        label-width="70px"
        @submit.native.prevent
      >
        <el-form-item label="跟进日期:">
          <el-date-picker
            v-model="model.followTimeRange"
            type="daterange"
            value-format="yyyy-MM-dd"
            start-placeholder="请选择"
            end-placeholder="请选择"
          />
        </el-form-item>

        <el-form-item label="直播平台:">
          <live-platform v-model="model.platformId" />
        </el-form-item>

        <el-form-item label="游戏版块:">
          <game-category v-model="model.categoryId" />
        </el-form-item>

        <el-form-item label="跟进状态:">
          <follow-status v-model="model.status" />
        </el-form-item>

        <el-form-item label="搜索:">
          <el-input
            v-model.trim="model.keyword"
            placeholder="主播名称、直播间号、商务"
            clearable
            @keyup.native.enter="handleFormSearchClick"
          />
        </el-form-item>

        <el-form-item>
          <el-button
            type="primary"
            icon="el-icon-search"
            :loading="searching"
            @click="handleFormSearchClick"
            >查询
          </el-button>

          <el-button icon="el-icon-refresh" @click="handleFormResetClick"
            >重置
          </el-button>
        </el-form-item>
      </el-form>
    </div>

    <!-- 操作栏 -->
    <div class="anchor-follow-operate">
      <el-button
        type="primary"
        icon="el-icon-plus"
        size="small"
        @click="handleOperateAddClick"
        >新增
      </el-button>
    </div>

    <!-- 数据表格 -->
    <div class="anchor-follow-table">
      <el-table v-loading="loading" ref="table" :data="follows" size="small">
        <el-table-column label="主播名称" prop="anchorName" />

        <el-table-column label="直播平台" prop="platformName" />

        <el-table-column label="直播间号" prop="roomNo" />

        <el-table-column label="游戏板块" prop="categoryName" />

        <el-table-column label="粉丝牌" prop="fansName" />

        <el-table-column label="贵宾数" prop="vipName" />

        <el-table-column label="热度" prop="heatName" />

        <el-table-column label="订阅量" prop="subscriptionName" />

        <el-table-column label="状态">
          <template v-slot="{ row: { status } }">
            <el-tag :type="status | followStatusTagType" size="mini">
              {{ status | followStatusText }}
            </el-tag>
          </template>
        </el-table-column>

        <el-table-column label="商务" prop="username" />

        <el-table-column label="创建时间" prop="createTime" width="180px" />

        <el-table-column label="操作" width="90px">
          <template v-slot="{ row }">
            <div class="table-row-operate">
              <el-button
                v-if="
                  currentUserHasFullPermissions || row.userId === currentUserId
                "
                icon="el-icon-edit"
                circle
                size="mini"
                @click="handleTableRowEditClick(row)"
              />

              <el-button
                plain
                type="primary"
                icon="el-icon-phone-outline"
                circle
                size="mini"
                @click="handleTableRowContactClick(row)"
              />
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <!-- 分页器 -->
    <div class="anchor-follow-pagination">
      <el-pagination
        :total="total"
        :current-page="pageNo"
        :page-sizes="pageSizes"
        background
        layout="total, sizes, prev, pager, next, jumper"
        @current-change="handlePageNoChange"
        @size-change="handlePageSizeChange"
      />
    </div>

    <!-- 新增/编辑跟进对话框 -->
    <follow-edit
      v-model="showEditDialog"
      @on-ok="handleFollowEditDialogOkClick"
    />

    <!-- 联系记录抽屉 -->
    <follow-contact v-model="showContactDrawer" />
  </div>
</template>

<script>
  import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';
  import LivePlatform from '../../../components/live-platform';
  import GameCategory from '../../../components/game-category';
  import FollowStatus from '../../../components/follow-status';
  import { pageSize, pageSizes } from '../../../config/pagination';
  import followStatus from '../../../enum/follow-status.js';

  export default {
    name: 'anchor-follow',
    components: {
      LivePlatform,
      GameCategory,
      FollowStatus,
      FollowEdit: () =>
        import(/* webpackChunkName: 'follow-edit' */ './follow-edit'),
      FollowContact: () =>
        import(/* webpackChunkName: 'follow-contact' */ './follow-contact'),
    },
    filters: {
      // 跟进状态标签类型
      followStatusTagType(val) {
        const typeMap = {
          [followStatus.enum.following]: 'info',
          [followStatus.enum.followed]: 'success',
        };

        return typeMap[val];
      },
      // 跟进状态文案
      followStatusText(val) {
        return followStatus.map[val] || '';
      },
    },
    data() {
      return {
        model: null,
        pageNo: 1,
        pageSize,
        pageSizes,
        operateId: '',
        showEditDialog: false,
        showContactDrawer: false,
        loading: false,
        searching: false,
      };
    },
    computed: {
      ...mapState('anchor/follow', ['follows', 'total']),
      ...mapGetters(['currentUserId', 'currentUserHasFullPermissions']),

      // 跟进状态枚举值
      followStatusEnum() {
        return followStatus.enum;
      },
    },
    created() {
      this.initModel();
    },
    mounted() {
      this.getTableData();
    },
    beforeDestroy() {
      this.mttFollowId();
    },
    methods: {
      ...mapMutations('anchor/follow', ['mttFollowId']),
      ...mapActions('anchor/follow', ['actFollows']),

      // 初始化模型
      initModel() {
        this.model = {
          followTimeRange: [],
          platformId: '',
          categoryId: '',
          status: '',
          keyword: '',
        };
      },
      // 获取表格数据
      async getTableData() {
        const { followTimeRange } = this.model;
        const [startTime = '', endTime = ''] = followTimeRange || [];

        this.loading = true;

        const success = await this.actFollows({
          ...this.model,
          startTime,
          endTime,
          followTimeRange: undefined,
          pageNo: this.pageNo,
          pageSize: this.pageSize,
        });

        this.loading = false;

        return success;
      },
      // 表单查询按钮单击
      async handleFormSearchClick() {
        this.pageNo = 1;
        this.searching = true;

        await this.getTableData();

        this.searching = false;
      },
      // 表单重置按钮单击
      handleFormResetClick() {
        this.pageNo = 1;

        this.initModel();
        this.getTableData();
      },
      // 操作栏新增按钮单击
      handleOperateAddClick() {
        this.operateId = '';
        this.showEditDialog = true;
      },
      // 表格行编辑按钮单击
      handleTableRowEditClick({ _id }) {
        this.operateId = _id;
        this.showEditDialog = true;
      },
      // 表格行联系按钮单击
      handleTableRowContactClick({ _id }) {
        this.operateId = _id;
        this.showContactDrawer = true;
      },
      // 新增/编辑跟进对话框确定按钮执行成功
      handleFollowEditDialogOkClick() {
        this.getTableData();
      },
      // 分页器页码改变
      handlePageNoChange(pageNo) {
        this.pageNo = pageNo;

        this.getTableData();
      },
      // 分页器条目数改变
      handlePageSizeChange(pageSize) {
        this.pageSize = pageSize;

        this.getTableData();
      },
    },
    watch: {
      operateId(n, o) {
        this.mttFollowId(n);
      },
    },
  };
</script>

<style scoped lang="scss">
  .anchor-follow {
    // 查询表单
    .anchor-follow-form {
      max-width: 85%;

      .el-form-item {
        margin-right: 70px;
      }

      .el-input,
      .el-select,
      .el-date-editor {
        width: 220px;
      }
    }

    // 数据表格
    .anchor-follow-table {
      margin-top: 20px;

      // 操作按钮
      .table-row-operate {
        .el-button {
          min-width: auto;
        }
      }
    }

    // 分页器
    .anchor-follow-pagination {
      margin-top: 20px;
    }
  }
</style>
